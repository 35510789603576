import React from "react";
import "../../Css/main.css"

function Footer() {
  return (
    <div>
      {" "}
      <footer>
        <div class="container">
          <div class="back-to-top">
            <a href="#hero">
              {" "}
              <i class="fas fa-chevron-up"></i>{" "}
            </a>
          </div>
          <div class="footer-content">
            <div class="footer-content-about animate-top">
              <h4>About Rosa</h4>
              <div class="asterisk">
                {" "}
                <i class="fas fa-asterisk"></i>
              </div>
              <p>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Facere
                architecto neque aliquam dignissimos minima corporis eius
                tempora amet magni. Facere molestias recusandae soluta id aut
                assumenda temporibus blanditiis quasi magnam!
              </p>
            </div>
            <div class="content-divider  animate-bottom">
              <div class="social-media">
                <h4>Follow Along</h4>
                <ul class="social-icons">
                  <li>
                    <a href="#">
                      {" "}
                      <i class="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      {" "}
                      <i class="fab fa-facebook-square"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      {" "}
                      <i class="fab fa-linkedin-in"></i>
                    </a>
                  </li>

                  <li>
                    <a href="#">
                      {" "}
                      <i class="fa fa-pinterest"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      {" "}
                      <i class="fa fa-tripadvisor"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="newsletter-container">
                <h4>Newsletter</h4>
                <form action="" class="newsletter-form">
                  <input
                    type="text"
                    class="newsletter-input"
                    placeholder="Your email address"
                  />
                  <button class="newsletter-btn" type="submit">
                    <i class="fas fa-envelope"></i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
