import React from 'react'
import "../../Css/main.css"

function Header() {
  return (
    <div>   <header>
    <div class="container">

        <nav class="nav">
            <div class="menu-toggle">
                <i class="fas fa-bars"></i>
                <i class="fas fa-times"></i>
            </div>
            <a href="/Users/amalfarah/Desktop/the rosa wesite/html/index.html" class="logo">
                <img src="/Users/amalfarah/Desktop/the rosa wesite/img/logo-rosa1.png" alt=""/></a>
            <ul class="nav-list">
                <li class="nav-item">
                    <a href="/Users/amalfarah/Desktop/the rosa wesite/html/index.html" class="nav-link active">Home</a>
                </li>

                <li class="nav-item">
                    <a href="#" class="nav-link">Menu</a>
                </li>

                <li class="nav-item">
                    <a href="#" class="nav-link">Reservations</a>
                </li>

                <li class="nav-item">
                    <a href="#" class="nav-link">News</a>
                </li>

                <li class="nav-item">
                    <a href="#" class="nav-link">Shop</a>
                </li>

                <li class="nav-item">
                    <a href="#" class="nav-link">Contact</a>
                </li>
            </ul>
        </nav>
    </div>
</header></div>
  )
}

export default Header