import React from "react";
import "../../Css/main.css";

function Hero() {
  return (
    <div>
      {" "}
      <section class="hero" id="hero">
        <div class="container">
          <h2 class="sub-headline">
            <span class="first-letter">W</span>elcome
          </h2>
          <h1 class="headline">Lassi Den</h1>
          <div class="headline-description">
            <div class="separator">
              <div class="line line-left"></div>
              <div class="asterisk">
                {" "}
                <i class="fas fa-asterisk"></i>
              </div>
              <div class="line line-right"></div>
            </div>
            <div class="single-animation">
              <h5>Grab Your Opportunity</h5>
              <a href="#" class="btn cta-btn">
                Get your business
              </a>
            </div>
          </div>
        </div>
      </section>
      <section class="disccover-our-story">
        <div class="container">
          <div class="restaurant-info">
            <div class="restaurant-descripton padding-right animate-left">
              <div class="global-headline">
                <h2 class="sub-headline">
                  <span class="first-letter">D</span>iscover
                </h2>
                <h1 class="headline headline-dark">Our Story</h1>
                <div class="asterisk">
                  {" "}
                  <i class="fas fa-asterisk"></i>
                </div>
              </div>
              <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ut
                earum placeat quis quibusdam recusandae expedita similique vel
                eligendi atque perferendis pariatur sapiente non aliquid
                reiciendis quos, minima, dolorem debitis asperiores?
              </p>
              <a href="#" class="btn body-btn">
                About Us
              </a>
            </div>
            <div class="restaurant-info-img animate-right ">
              <img
                src="https://github.com/Afarah92/the-rosa-restuarant/blob/master/the%20rosa%20wesite/img/our-story-1.jpg?raw=true"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Hero;
