import logo from './logo.svg';
import './App.css';
import Header from './Components/Header/Header';
import Hero from './Components/Hero/Hero';
import Section from './Components/Section/Section';
import Footer from './Components/Footer/Footer';

function App() {
  return (
    <div className="App">
    <Header/>
    <Hero/>
    <Section/>
    <Footer/>
    </div>
  );
}

export default App;
