import React from "react";
import "../../Css/main.css"

function Section() {
  return (
    <div>
      {" "}
      <section class="tasteful-recipes between">
        <div class="container">
          <div class="global-headline">
            <div class="animate-top">
              <h2 class="sub-headline">
                <span class="first-letter">T</span>asteful
              </h2>
            </div>
            <div class="animate-bottom">
              <h1 class="headline ">Recipes</h1>
            </div>
          </div>
        </div>
      </section>
      <section class="discover-our-menu">
        <div class="container">
          <div class="restaurant-info">
            <div class="image-group padding-right animate-left">
              <img
                src="https://github.com/Afarah92/the-rosa-restuarant/blob/master/the%20rosa%20wesite/img/menu-group-1.jpg?raw=true"
                alt=""
              />
              <img
                src="https://github.com/Afarah92/the-rosa-restuarant/blob/master/the%20rosa%20wesite/img/menu-group-2.jpg?raw=true"
                alt=""
              />
              <img
                src="https://github.com/Afarah92/the-rosa-restuarant/blob/master/the%20rosa%20wesite/img/menu-group-3.jpg?raw=true"
                alt=""
              />
              <img
                src="https://github.com/Afarah92/the-rosa-restuarant/blob/master/the%20rosa%20wesite/img/menu-group-4.jpg?raw=true"
                alt=""
              />
            </div>
            <div class="restaurant-description animate-right">
              <div class="global-headline">
                <h2 class="sub-headline">
                  <span class="first-letter">D</span>iscover
                </h2>
                <h1 class="headline headline-dark">Menu</h1>
                <div class="asterisk">
                  {" "}
                  <i class="fas fa-asterisk"></i>
                </div>
              </div>

              <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ut
                earum placeat quis quibusdam recusandae expedita similique vel
                eligendi atque perferendis pariatur sapiente non aliquid
                reiciendis quos, minima, dolorem debitis asperiores?
              </p>
              <a href="#" class="btn body-btn">
                View the full Menu
              </a>
            </div>
          </div>
        </div>
      </section>
      <section class="perfect-blend between">
        <div class="container">
          <div class="global-headline">
            <div class="animate-top">
              <h2 class="sub-headline">
                <span class="first-letter">T</span>he Perfect
              </h2>
            </div>
            <div class="animate-bottom">
              <h1 class="headline ">Blend</h1>
            </div>
          </div>
        </div>
      </section>
      <section class="culinary-delight">
        <div class="container">
          <div class="restaurant-info">
            <div class="restaurant-descripton padding-right animate-left">
              <div class="global-headline">
                <h2 class="sub-headline">
                  <span class="first-letter">C</span>ulinary
                </h2>
                <h1 class="headline headline-dark">Delight</h1>
                <div class="asterisk">
                  {" "}
                  <i class="fas fa-asterisk"></i>
                </div>
              </div>
              <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ut
                earum placeat quis quibusdam recusandae expedita similique vel
                eligendi atque perferendis pariatur sapiente non aliquid
                reiciendis quos, minima, dolorem debitis asperiores?
              </p>
              <a href="#" class="btn body-btn">
                Make a reservation
              </a>
            </div>
            <div class="image-group">
              <img
                class="animate-top"
                src="https://github.com/Afarah92/the-rosa-restuarant/blob/master/the%20rosa%20wesite/img/delight-group-1.jpg?raw=true"
                alt=""
              />
              <img
                class="animate-bottom"
                src="https://github.com/Afarah92/the-rosa-restuarant/blob/master/the%20rosa%20wesite/img/delight-group-2.jpg?raw=true"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Section;
